import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { createAffiliateLink } from '@legacyApp/client/modules/app/affiliateService';
import { roundNumber } from '@common/methods/roundNumber/roundNumber';
import sortService, {
	DIRECTION,
} from '../../../LegacyApp/client/modules/app/sortService';
import { TABLE_ID, TableIdType } from '../constants/TableId';
import { TransactionsResponseType } from '../constants/TransactionsResponse.type';

export const mapKeyData = (key: string, data: any) => {
	if (!data[key] && data.data?.length) {
		data[key] = data.data;
		delete data.data;
	}
	return data;
};

const mapFundsData = (data: TransactionsResponseType) => {
	if (!data.funds) {
		return false;
	}
	return data.funds
		.map((el) => ({
			...el,
			available: roundNumber(
				parseFloat(el.possible_profit_on_first_level || 0) +
					parseFloat(el.available),
				14,
			),
			total_commission: roundNumber(
				parseFloat(el.available) + parseFloat(el.withdrawn),
				14,
			),
			id: el.currency,
			modified_at: el.modified_at || getTimestamp(),
		}))
		.filter((fund) => !!fund.total_commission)
		.sort((a, b) => sortService.sort(DIRECTION.DESC, a.currency, b.currency));
};
const mapCampaignsData = (data: TransactionsResponseType) => {
	data = mapKeyData('campaigns', data);
	if (!data.campaigns) {
		return false;
	}
	return data.campaigns
		.map((el) => {
			return {
				...el,
				id: el.id || el.name,
				currency: 'btc',
			};
		})
		.sort((a, b) =>
			sortService.sort(
				DIRECTION.DESC,
				a.id,
				b.id,
				a.published_at,
				b.published_at,
			),
		);
};
const mapAffiliateCampaignsData = (data: TransactionsResponseType) => {
	return data.data
		.map((el) => ({
			...el.campaign,
			link: createAffiliateLink(el.campaign.shortcut),
		}))
		.sort((a, b) =>
			sortService.sort(
				DIRECTION.DESC,
				a.id,
				b.id,
				a.published_at,
				b.published_at,
			),
		);
};
const mapDepositsData = (data: TransactionsResponseType) => {
	return (data.pending_deposits || [])
		.filter((el) => !data.deposits.some((deposit) => el.hash === deposit.hash))
		.map((el) => ({
			...el,
			state: 'processing',
		}))
		.concat(data.deposits || [])
		.concat(data.data)
		.map((el) => {
			if (!el.hash) {
				el.hash = el.uuid;
			}
			return el;
		});
};
const mapOtherTransactionsData = (data: TransactionsResponseType) => {
	data = mapKeyData('transactions', data);
	if (!data.transactions) {
		return false;
	}
	return data.transactions.map((el, index) => ({
		...el,
		id:
			el.id ||
			`otherTransactions_${el.published_at}_${el.type_label}_${el.currency}_${el.amount}${index}`,
	}));
};

const mapTransactionsData = (
	id: TableIdType,
	data: TransactionsResponseType,
) => {
	if (!data) {
		return false;
	}
	switch (id) {
		case TABLE_ID.vaultHistory: {
			return data.data || false;
		}
		case TABLE_ID.rakebackFunds: {
			return mapFundsData(data);
		}
		case TABLE_ID.affiliateFunds: {
			return mapFundsData(data);
		}
		case TABLE_ID.affiliateAnalytics: {
			return mapCampaignsData(data);
		}
		case TABLE_ID.affiliateCampaigns: {
			return mapAffiliateCampaignsData(data);
		}
		case TABLE_ID.withdrawHistory: {
			data = mapKeyData('withdraws', data);
			return data.withdraws || false;
		}
		case TABLE_ID.depositHistory: {
			return mapDepositsData(data);
		}
		case TABLE_ID.otherTransactions: {
			return mapOtherTransactionsData(data);
		}
		case TABLE_ID.affiliateCashoutHistory: {
			return data.data || false;
		}
		case TABLE_ID.friendsIgnored: {
			return data.data || false;
		}
		case TABLE_ID.sessions: {
			return data.data || false;
		}
		case TABLE_ID.sessionHistory: {
			data = mapKeyData('bets', data);
			return data.bets || false;
		}
		case TABLE_ID.affiliateCampaignUsers: {
			data = mapKeyData('users', data);
			return data.users || [];
		}
		case TABLE_ID.affiliateCampaignHistory: {
			data = mapKeyData('history', data);
			return data.history || [];
		}
		case TABLE_ID.lastRacesResults: {
			data = mapKeyData('race_winners', data);
			return data.race_winners || [];
		}
		default: {
			return data.data || false;
		}
	}
};

export const parseTransactionsData = <T>({
	id,
	data,
}: {
	id: TableIdType;
	data: TransactionsResponseType<T>;
}) => {
	// console.log('parseTransactions', id, data);
	const data_ = mapTransactionsData(id, data);
	return {
		data: data_,
		meta: data?.meta || null,
	};
};
