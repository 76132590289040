import { cookieService } from '@common/controllers/cookieService';
import { config, FeatureType } from '../../config';
import { RootState } from '../../store/configure.store';

export const isFeatureAvailable = (id: FeatureType) => {
	if (!config.disableFeatures[id]) {
		// console.log('isFeatureAvailable', id, 'not-disabled');
		return true;
	}
	if (
		!config.extendedAccessFunction ||
		config.extendedAccessFunction.indexOf(id) === -1
	) {
		// console.log('isFeatureAvailable', id, 'disabled');
		return false;
	}
	// console.log('isFeatureAvailable', id, 'extended', cookieService.get('extendedAccess'));
	return !!cookieService.get('extendedAccess');
};

export const checkIfUserCanAccess = (state: RootState) => {
	// console.log('extendedAccessEpic', {
	// 	isLogin: config.loginsWithExtendedAccess.indexOf(store$.value.user.name) > -1,
	// 	bool: config.loginsWithExtendedAccess.indexOf(store$.value.user.name) > -1 || store$.value.user.isAdmin || !!store$.value.vip.level?.level,
	// 	isAdmin: store$.value.user.isAdmin,
	// 	level: store$.value.vip.level?.level,
	// });
	return (
		state.user.isAdmin || state.user.isModerator
		// || !!state.vip.level?.level
	);
};
