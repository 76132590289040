import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import NewFeatures from '../../mocks/features';

export const isNewFeatures = (onlyCheck) => {
	if (!NewFeatures.length) {
		return false;
	}
	const lastRead = lsGet(LocalStorageKeys.NEW_FEATURES_TIMESTAMP);
	if (!onlyCheck) {
		lsSet(LocalStorageKeys.NEW_FEATURES_TIMESTAMP, NewFeatures[0].date);
	}
	if (!lastRead) {
		return true;
	}
	if (lastRead && NewFeatures[0].date > lastRead) {
		return onlyCheck;
	}
	return false;
};
