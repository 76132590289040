import { cookieService } from '@common/controllers/cookieService';

import { stringToJson } from '@common/methods/stringToJson';

export const getTokenFromPreloadedState = (preloadedState) => {
	// console.log('getTokenFromPreloadedState', from, preloadedState);
	preloadedState = stringToJson(preloadedState, preloadedState);
	const token =
		preloadedState && preloadedState.data && preloadedState.data.access_token
			? preloadedState.data
			: undefined;
	if (token && process.browser) {
		cookieService.clear('preloaded_state');
	}
	return token;
};

export const getServerToken = (ctx) => {
	if (!ctx || !ctx.res) {
		return undefined;
	}
	const preloadedStateToken = getTokenFromPreloadedState(
		ctx.res.locals.preloadedState || ctx.req.cookies.preloaded_state,
		'serverToken2',
	);
	// console.log('getServerToken', {
	// 	ctxResLocals: ctx.res.locals,
	// 	ctxReqCookies: ctx.req.cookies,
	// 	cookiesToken: ctx.req.cookies.token,
	// 	preloadedStateToken,
	// });
	return ctx.req.cookies.token || preloadedStateToken || undefined;
};
