import Router from 'next/router';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { generateUserSportsBetSlipsHistory } from '@modules/sports/services/sports.services';
import { getPathLocalized } from '@common/methods/getPathLocalized/getPathLocalized';

class RoutingClass {
	constructor() {
		this.routes = [
			{
				uri: '',
				id: ROUTING_ID.HOME,
			},
			{
				uri: 'casino/:game',
				id: ROUTING_ID.GAME,
			},
			{
				uri: 'login',
				id: ROUTING_ID.LOGIN,
				isOnlyPublic: true,
				// static: true,
			},
			{
				uri: 'register',
				id: ROUTING_ID.REGISTER,
				isOnlyPublic: true,
				// static: true,
			},
			{
				uri: 'access_denied',
				id: ROUTING_ID.ACCESS_DENIED,
				static: true,
			},
			{
				uri: 'user/deposit',
				id: ROUTING_ID.USER_DEPOSIT,
				isPrivate: true,
			},
			{
				uri: 'user/withdraw',
				id: ROUTING_ID.USER_WITHDRAW,
				isPrivate: true,
			},
			{
				uri: 'user/settings',
				id: ROUTING_ID.USER_SETTINGS,
				isPrivate: true,
			},
			{
				uri: 'user/transactions',
				id: ROUTING_ID.USER_ALL_TRANSACTIONS,
				isPrivate: true,
			},
			{
				uri: generateUserSportsBetSlipsHistory({ state: 'pending' }),
				id: ROUTING_ID.USER_SPORTS_HISTORY,
				isPrivate: true,
			},
			{
				uri: 'user/email/verified',
				id: ROUTING_ID.USER_EMAIL_VERIFIED,
			},
			{
				uri: 'user/email/verification-failed',
				id: ROUTING_ID.USER_EMAIL_VERIFICATION_FAILED,
			},
			{
				uri: 'user/account/blocked',
				id: ROUTING_ID.USER_ACCOUNT_BLOCKED,
				isOnlyPublic: true,
			},
			{
				uri: 'user/account/blocked-error',
				id: ROUTING_ID.USER_ACCOUNT_BLOCKED_ERROR,
				isOnlyPublic: true,
			},
			{
				uri: 'user/new-password/*',
				id: ROUTING_ID.USER_NEW_PASSWORD,
				isOnlyPublic: true,
			},
			{
				uri: 'user/affiliate',
				id: ROUTING_ID.USER_AFFILIATE,
			},
			{
				uri: 'vip',
				id: ROUTING_ID.USER_VIP,
			},
			{
				uri: 'user/vip/bonus-code',
				id: ROUTING_ID.USER_VIP_BONUS_CODE,
			},
			{
				uri: 'user/verification',
				id: ROUTING_ID.USER_VERIFICATION,
			},
			{
				uri: 'faq',
				id: ROUTING_ID.FAQ,
			},
			{
				uri: 'terms',
				id: ROUTING_ID.TERMS,
			},
			{
				uri: 'provably-fair',
				id: ROUTING_ID.PROVABLY_FAIR,
			},
			{
				uri: 'remind-password',
				id: ROUTING_ID.REMIND_PASSWORD,
				isOnlyPublic: true,
			},
			{
				uri: 'promotions',
				id: ROUTING_ID.PROMOTIONS,
			},
			{
				uri: 'ranking',
				id: ROUTING_ID.RANKING,
			},
			{
				uri: 'bets',
				id: ROUTING_ID.BETS,
			},
		];
	}

	getRoutes() {
		return [
			...this.routes,
			{
				uri: 'sportsbook',
				id: ROUTING_ID.SPORTS_BOOK,
			},
			{
				uri: 'sports',
				id: ROUTING_ID.SPORTS,
			},
			{
				uri: 'sports/live',
				id: ROUTING_ID.SPORTS_LIVE,
			},
			{
				uri: 'sports/menu',
				id: ROUTING_ID.SPORTS_MENU,
			},
		];
	}

	to(id, validUri) {
		const splitedId = this.splitId(id);
		return this.createUrl(
			validUri ? splitedId[0] : this.getUri(splitedId[0]),
			splitedId[1],
		);
	}

	splitId(id) {
		return id.split('?');
	}

	getRoute(uri) {
		uri = uri[0] === '/' ? uri.substring(1) : uri;
		uri = uri[0] === '/' ? uri.substring(1) : uri;
		if (uri.indexOf('new-password') > -1) {
			const uriArray = uri.split('/');
			const token = uriArray[uriArray.length - 1];
			uri = uri.replace(token, '*');
		}
		if (uri.indexOf('casino/') > -1) {
			uri = 'casino/:game';
		}
		return this.getRoutes().find((el) => el.uri === uri);
	}

	createUrl(uri, query) {
		return `${uri ? `${uri[0] === '/' ? uri : `/${uri}`}` : '/'}${
			query ? `?${query}` : ''
		}`;
	}

	getRouteById(id) {
		return this.getRoutes().find((el) => el.id === id);
	}

	getUri(id) {
		const element = this.getRouteById(id);
		return element ? element.uri : '';
	}

	redirect(id, validUri, noLocale) {
		const pathname = this.to(id, validUri);
		// console.log('static - redirect', { id, validUri, location: window.location.origin, pathname, res: `${window.location.origin}${noLocale ? pathname : getPathLocalized({ pathname })}` });
		if (process.browser) {
			window.location.href = `${window.location.origin}${
				noLocale ? pathname : getPathLocalized({ pathname })
			}`;
		}
	}

	dynamicRedirect(id, validUri) {
		// console.log('dynamic - redirect', { id, validUri, to: this.to(id, validUri) });
		return Router.push(this.to(id, validUri));
	}

	getId(uri) {
		const route = this.getRoute(uri);
		return route ? route.id : '';
	}
}

export default new RoutingClass();
