import styled from 'styled-components';
import { NextComponentType } from 'next';
import { ReactNode } from 'react';
import { StyledMainDashboardV2 } from '@legacyApp/client/modules/style/components/MainDashboard.styled';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import SeoPageContent from '@common/components/text/SeoPageContent/SeoPageContent';
import { NextSeoProps } from 'next-seo/lib/types';
import { PageContentType } from '@common/components/text/SeoPageContent/PageContentType';
import { CurrenciesSettings } from '@legacyApp/types/wallet/CurrenciesSettings';
import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import { isFunction } from '@common/methods/isFunction';
import { CurrencyType, Token } from '@legacyApp/types/wallet/CurrencyType';
import Seo from '../components/default/Seo/Seo';
import { Content } from '../components/text/Content/Content';

export interface EnhancedNextComponentPageProps {
	id: string;
	locale?: string;
	namespaces?: Array<TransNamespace>;
	currenciesSettings?: {
		settings: CurrenciesSettings;
		list: CurrencyType[];
		tokens: Token[];
	};
	params?: {
		locale: string;
		page_content?: PageContentType;
	};
	props: {
		noChat: boolean;
		noHeader: boolean;
		noBottomMenu: boolean;
		noFooter: boolean;
		noSupport: boolean;
		fullscreenLoading?: boolean;
	};
}

export interface EnhancedPageProps {
	id: string;
	noSeo?: boolean;
	seoProps?: NextSeoProps;
	noChat?: boolean;
	noHeader?: boolean;
	noBottomMenu?: boolean;
	noFooter?: boolean;
	noSupport?: boolean;
	pageDescription?:
		| Record<string, any>
		| ((pageProps: EnhancedNextComponentPageProps) => Record<string, any>);
	structuredData?: ReactNode;
	seoNamespace?: TransNamespace;
	fullscreenLoading?: boolean;
}

export type EnhancedNextComponent = NextComponentType<
	{},
	{},
	EnhancedNextComponentPageProps
> &
	Partial<{
		pageName: string;
		pageConfig: EnhancedNextComponentPageProps;
	}>;

export const StyledTextWrapper = styled(StyledMainDashboardV2)`
	padding-bottom: 20px;
	padding-top: 20px;
	position: relative;

	.locked-view & {
		position: absolute;
		top: 100%;
	}

	h1 {
		margin-bottom: 20px;
	}

	h2 {
		margin-top: 15px;
	}

	p {
		margin: 5px 0;
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		&:before {
			background: ${(props) => props.theme.colors.headerActiveBackground};
			content: '';
			display: block;
			height: calc(100% + 115px);
			left: 50%;
			position: absolute;
			top: 0;
			transform: translateX(-50%);
			width: 100vw;
			z-index: -1;
		}
	}
`;

export const pageEnhancer = (
	Component: EnhancedNextComponent,
	{
		id,
		noSeo,
		seoProps,
		noChat,
		noHeader,
		noBottomMenu,
		noFooter,
		noSupport,
		seoNamespace,
		pageDescription,
		structuredData,
		fullscreenLoading,
	}: EnhancedPageProps,
): ((pageProps: {}) => void) => {
	const data = {};
	data[id] = (pageProps: EnhancedNextComponentPageProps) => {
		currenciesSettings.start(pageProps.currenciesSettings);
		const pageDescription_ =
			isFunction(pageDescription) && typeof pageDescription === 'function'
				? pageDescription(pageProps)
				: pageDescription;
		return (
			<>
				{!noSeo && (
					<Seo
						title={id}
						props={seoProps}
						pageParams={pageProps.params}
						namespace={seoNamespace}
					/>
				)}
				{structuredData || null}
				<Component {...pageProps} />
				{pageProps?.params?.page_content ? (
					<StyledTextWrapper>
						<SeoPageContent
							{...pageProps?.params?.page_content}
							options={pageDescription_?.options}
						/>
					</StyledTextWrapper>
				) : pageDescription_ ? (
					<StyledTextWrapper>
						<Content {...pageDescription_} />
					</StyledTextWrapper>
				) : null}
			</>
		);
	};

	data[id].pageConfig = {
		id,
		props: {
			noChat,
			noHeader,
			noBottomMenu,
			noFooter,
			noSupport,
			fullscreenLoading,
		},
	};

	data[id].pageName = id;

	return data[id];
};
