import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';
import { Button, ButtonProps } from '@ui/button';
import styled, { css } from 'styled-components';
import { IconProps } from '@icons/all-bets';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { theme } from '@legacyApp/client/modules/style/theme';

type TabButtonOnClick = (
	event: MouseEvent,
	props: {
		id: string;
		active: boolean;
	},
) => void;

export interface TabButtonProps
	extends Omit<
		ButtonProps,
		'iconBefore' | 'iconAfter' | 'disabled' | 'size' | 'styleType' | 'onClick'
	> {
	small?: boolean;
	icon?: (props: IconProps) => ButtonProps['iconBefore'];
	active?: boolean;
	id: string;
	content?: FC;
	onClick?: TabButtonOnClick;
}

export const getTabButtonActiveColors = (active: boolean): IconProps => {
	return {
		fill: active
			? theme.ui.colors['other-primary-gradient-to']
			: theme.ui.colors['dark-50'],
		fillStop: active ? theme.ui.colors['other-primary-gradient-from'] : null,
	};
};

export const TabButton: FC<TabButtonProps> = (props) => {
	const { label, small, icon, active, onClick, content, ...restProps } = props;

	const onClick_: MouseEventHandler = useCallback(
		(event) => {
			if (onClick) {
				onClick(event, {
					id: restProps.id,
					active,
				});
			}
		},
		[active, onClick, restProps.id],
	);

	return (
		<TabButtonStyled
			className="text-style-sm-medium"
			as={Button}
			size={small ? Size.MEDIUM : Size.LARGE}
			iconBefore={
				typeof icon === 'function'
					? icon(getTabButtonActiveColors(active))
					: null
			}
			styleType={ButtonStyleType.SECONDARY}
			$active={active}
			onClick={onClick_}
			{...restProps}
		>
			<span>{label}</span>
		</TabButtonStyled>
	);
};

interface TabButtonStyledProps extends ButtonProps {
	$active: TabButtonProps['active'];
}

export const TabButtonStyled = styled.button<TabButtonStyledProps>`
	${(props) =>
		!props.$active
			? css`
					&:not(:hover):not(:active) {
						background: transparent;
						border-color: transparent;
						color: var(--color-dark-50);
					}
			  `
			: null};

	${(props) =>
		props.size === Size.MEDIUM
			? css`
					line-height: 16px;
			  `
			: css`
					padding-bottom: 10px;
					padding-top: 10px;
			  `}

	svg {
		margin-right: 4px;
	}

	:focus {
		box-shadow: unset;
	}
`;
