import React, { FC } from 'react';
import { useAppIsMaintenanceMode } from '@common/selectors/app.selectors';
import { Loadable } from '../loader/loadable/loadable';

const CoverLoadable = Loadable(
	{
		loader: () => import(/* webpackChunkName: "cover" */ './cover'),
		ssr: false,
	},
	'./cover',
);

export const Cover: FC = () => {
	const maintenanceMode = useAppIsMaintenanceMode();
	if (!maintenanceMode) {
		return null;
	}
	return <CoverLoadable />;
};
