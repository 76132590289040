import { createGlobalStyle } from 'styled-components';
import { ThemeType } from '@legacyApp/client/modules/style/theme';
import { mediumLayout } from '@legacyApp/client/modules/style/mixins';
import { SPORTSBOOK_PAGE_ID } from '@modules/sportsbook/constants/sportsbook.page.config';
import { SPORTS_PAGE_ID } from '@modules/sports/constants/sports.page.config';
import { SLOTS_PAGE_ID } from '@modules/slots/constants/slots.page.config';
import { ROUTING_ID } from '../../constants/routing/ids.routing.constants';
import { HomePageBackgroundMixin } from '../home/Home.global.styled';

export const StyledPagesGlobal = createGlobalStyle<{ theme: ThemeType }>`
	#__next {
		> [class*="page-"] {
			height: auto;
			min-height: 100%;
			width: 100%;
			float: left;

			.main-dashboard > h1 {
				display: flex;
				align-items: center;

				&.page-title:after {
					display: block;
					height: 30px;
					width: 30px;
					margin-left: 15px;
				}
			}

			.information {
				margin-top: 40px;
			}

			.locked-view & {
				overflow: hidden;
			}

			@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
				body:not(.locked-view) & {
					padding-bottom: ${(props) => props.theme.layout.bottomMenuHeight};
				}
			}
		}
	}

	[class*="page-${SLOTS_PAGE_ID.CATEGORY}"],
	[class*="page-${SLOTS_PAGE_ID.INDEX}"],
	[class*="page-user_sports"],
	.page-${ROUTING_ID.USER_VERIFICATION},
	.page-${ROUTING_ID.PROMOTIONS},
	[class*='page-${SPORTS_PAGE_ID.INDEX}'] {
		${HomePageBackgroundMixin};
	}

	[class*='page-${SPORTSBOOK_PAGE_ID.INDEX}'] {
		background: #111b28;

		.main {
			background-color: #111b28;
		}

		.header.scrolled {
			background-color: #142336!important;
		}

		:after {
			content: unset;
			background: unset;
		}

		:before {
			content: unset;
		}
	}

 	[class*="page-${SLOTS_PAGE_ID.INDEX}_lobby"] {
		:after {
			background: none;
		}
	}

	[class*="page-user_email_verified"],
	[class*="page-user_email_verificationFailed"],
	[class*="page-error"],
	[class*="page-404"],
	[class*="page-user_account_blocked"],
	[class*="page-user_account_blockedError"],
	.page-remindPassword,
	.page-${ROUTING_ID.USER_VIP_BONUS_CODE},
	.page-user_newPassword,
	.page-redirect {
		${HomePageBackgroundMixin};
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		.page-provablyFair,
		.page-user_settings,
		.page-user_affiliate,
		.page-user_vip {
			${HomePageBackgroundMixin};
		}
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
		.page-provablyFair,
		.page-user_settings,
		.page-user_affiliate {
			${mediumLayout};
		}
	}

	.page-user_deposit,
	.page-user_withdraw {
		${HomePageBackgroundMixin};
	}

	.page-user_allTransactions,
	.page-faq,
	.page-ranking,
	.page-terms {
		${mediumLayout};
	}

	.page-game,
	.locked-view {
		.header {
			background-color: transparent;
		}
	}

	.page-game {
		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			height: 100% !important;
		}
	}

	[class*="page-user_email_verified"],
	[class*="page-user_email_verificationFailed"],
	[class*="page-error"],
	[class*="page-404"],
	[class*="page-user_account_blocked"],
	[class*="page-user_account_blockedError"] {
		.buttons-box {
			display: flex;
			justify-content: center;
			width: 100%;

			.button {
				padding: 10px 20px!important;
				white-space: nowrap;
			}
		}
	}
`;
