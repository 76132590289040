import Router from 'next/router';
import { cookieService } from '@common/controllers/cookieService';

export const handleUrlQuery = (ctx) => {
	if (!ctx) {
		return;
	}
	const { c } = ctx?.req?.query ?? {};
	if (c) {
		// console.log('handleUrlQuery', ctx.req);
		const redirectLocation = `${ctx.req.url.split('?')[0]}`;
		if (ctx.res) {
			console.log('context/query - redirect', { c, redirectLocation });
			ctx.res.cookie('affiliate-code', c, {
				expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
			});
			ctx.res.redirect(301, redirectLocation);
		} else {
			console.log('router/query - redirect', { c, redirectLocation });
			cookieService.set('affiliate-code', c);
			Router.push(redirectLocation);
		}
	}
};
