import { of } from 'rxjs';
import { openModal } from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';
import { isFunction } from '@common/methods/isFunction';
import { cookieService } from '@common/controllers/cookieService';
import { createAuthToken } from '../../modules/app/userService';
import { userSessionEnded } from '../user/user.actions';
import FetchService from '../../modules/app/fetchService';
import { serverDataClear } from '../serverData/serverData.actions';
import { config } from '../../config';
import { toggleMaintenanceMode } from '../app/app.actions';
import { getStatsData } from '../globalStatistics/globalStatistics.actions';
import { codeRequired } from '../twoFactorAuthentication/twoFactorAuthentication.actions';
import { loaderThunk } from '../loader/loader.actions';
import { tokenService, checkToken } from '../../modules/app/tokenService';
import { getStringApiInfo } from '../../modules/app/errorsService';
import { second } from '../../modules/app/time';
import { handleErrorsAction } from './errors.thunk';
import { fetchResponse } from './fetch.actions';

let fetchErrorCounter = {};

const fetchCheckLimit = 3;

const OMIT_ERROR_HANDLING_ERRORS = ['canceled'];

export const parseProps = (props) => {
	// parsing due to old implementation of passing parameters to fetch, must keep proper order
	return Object.keys({
		url: props.url,
		parameters: props.parameters,
		method: props.method,
		loaderId: props.loaderId,
		lockByModal: props.lockByModal,
		disableErrorHandler: props.disableErrorHandler,
		twoFactorAuth: props.twoFactorAuth,
		fromState: props.fromState,
		bypassCaptcha: props.bypassCaptcha,
		delay: props.delay,
		token: props.token,
	}).map((key) => props[key]);
};

export const fetchApi = (
	url,
	parameters = undefined,
	method = undefined,
	loaderId = undefined,
	lockByModal = undefined,
	disableErrorHandler = undefined,
	twoFactorAuth = undefined,
	fromState = undefined,
	bypassCaptcha = undefined,
	delay = undefined,
	token = undefined,
) => {
	if (url?.url) {
		return fetchLocal(
			...parseProps({
				...url,
				url: FetchService.parseApiUrl(url.url),
			}),
		);
	}
	return fetchLocal(
		FetchService.parseApiUrl(url),
		parameters,
		method,
		loaderId,
		lockByModal,
		disableErrorHandler,
		twoFactorAuth,
		fromState,
		bypassCaptcha,
		delay,
		token,
	);
};

export const fetchApiAction = (props, callback, callbackCatch) => {
	return fetchLocalAction(
		{
			...props,
			url: FetchService.parseApiUrl(props.url),
		},
		callback,
		callbackCatch,
	);
};
export const fetchLocalAction = (props, callback, catchCallback) => {
	return (dispatch) => {
		const response = dispatch(fetchLocal(...parseProps(props)));
		return dispatch(
			fetchResponse({
				response,
				callback: callback ? callback : () => [],
				catchCallback: catchCallback ? catchCallback : () => of(),
			}),
		);
	};
};
export const fetchLocal = (
	url,
	parameters,
	method,
	loaderId,
	lockByModal,
	disableErrorHandler,
	twoFactorAuth,
	fromState,
	bypassCaptcha,
	delay,
	token,
) => {
	return (dispatch, getState) => {
		if (url && FetchService.isState(url) === 'pending') {
			// console.log(url, 'Fetch false - pending state', FetchService);
			return new Promise((resolve) => resolve(false));
		}
		// console.log(`fetchLocal - start ${url}`, {parameters, method, loaderId, lockByModal, disableErrorHandler, twoFactorAuth});
		if (twoFactorAuth && getState().twoFA.isActive) {
			return new Promise((resolve) => {
				return dispatch(
					codeRequired(url, parameters, method, resolve, loaderId),
				);
			});
		}
		dispatch(loaderThunk(true, loaderId));
		return new Promise((resolve) => {
			const callback = () => {
				return resolve(
					dispatch(
						fetchWrapper(
							url,
							parameters,
							method,
							lockByModal,
							fromState,
							disableErrorHandler,
							loaderId,
							token,
						),
					)
						.then((result) => {
							dispatch(loaderThunk(false, loaderId));
							// console.log('fetchLocal - result', {result, url, parameters, method, lockByModal, fromState, disableErrorHandler, loaderId, bypassCaptcha});
							return result
								? dispatch(
										handleErrorsAction(
											result,
											url,
											parameters,
											method,
											loaderId,
											false,
											disableErrorHandler,
										),
								  )
								: result;
						})
						.catch((error) => {
							if (
								!disableErrorHandler &&
								OMIT_ERROR_HANDLING_ERRORS.indexOf(error) === -1
							) {
								dispatch(
									handleErrorsAction(
										{
											status: 'failed',
										},
										url,
										parameters,
										method,
										loaderId,
									),
								);
							}
							console.error('fetchLocal - error', error, {
								url,
								parameters,
								method,
							});
							// sentryError(error, { url, parameters, method });
							dispatch(loaderThunk(false, loaderId));
							return false;
						}),
				);
			};
			if (!delay) {
				return callback();
			}
			setTimeout(() => callback(), delay);
		});
	};
};
export const handleStaticResponse = (data, status, options) => {
	return (dispatch) => {
		return dispatch(handleWrapperResponse(data, status, options)).then(
			(result) => {
				dispatch(serverDataClear());
				cookieService.clear('csrf_token');
				return (!options.disableErrors && result) || result.text
					? dispatch(
							handleErrorsAction(
								result,
								options.url,
								options.parameters,
								options.method,
								options.loaderId,
								options.isStatic,
							),
					  )
					: result;
			},
		);
	};
};
export const handleWrapperResponse = (
	data,
	responseParams,
	{
		url,
		parameters,
		method,
		lockByModal,
		fromState,
		disableErrors,
		isStatic,
		token,
	},
) => {
	const { status } = responseParams;
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			// console.log('handleWrapperResponse', {data, status}, {url, parameters, method, lockByModal, fromState, disableErrors, isStatic});
			const state = getState();
			if (OMIT_ERROR_HANDLING_ERRORS.indexOf(data) > -1) {
				return reject(data);
			}
			if (FetchService.isMaintenanceMode(status, data)) {
				return dispatch(
					handleMaintenanceMode(
						data,
						status,
						{
							url,
							parameters,
							method,
							lockByModal,
							fromState,
							disableErrors,
							isStatic,
							token,
						},
						resolve,
					),
				);
			}
			if (status === 444) {
				return dispatch(
					handleRepeatRequest(
						data,
						responseParams,
						{
							url,
							parameters,
							method,
							lockByModal,
							fromState,
							disableErrors,
							isStatic,
							token,
						},
						resolve,
					),
				);
			}
			if (status >= 500 || !status || !data || status === 429) {
				return dispatch(
					handleFailedRequest(
						data,
						responseParams,
						{
							url,
							parameters,
							method,
							lockByModal,
							fromState,
							disableErrors,
							isStatic,
							token,
						},
						resolve,
					),
				);
			}
			if (fetchErrorCounter[url]) {
				delete fetchErrorCounter[url];
			}
			FetchService.addToHistory(url, status, data, parameters);
			if (state.app.maintenanceMode) {
				dispatch(toggleMaintenanceMode(false));
			}
			if (typeof data === 'object' && data.length !== undefined) {
				data = {
					data,
				};
			}
			return resolve({
				...data,
				...(data.hasOwnProperty('status')
					? { responseStatus: status }
					: { status }),
			});
		});
	};
};

const handleRepeatRequest = (
	data,
	responseParams,
	{ url, parameters, method, lockByModal, fromState, token, disableErrors },
	resolve,
	defaultTimeout = second,
) => {
	return (dispatch) => {
		const { headers } = responseParams;
		const timeout = (parseFloat(headers['retry-after']) || 1) * defaultTimeout;
		// console.log('handleFailedRequest - setTimeout', { url, parameters, method, headers, timeout });
		return setTimeout(() => {
			resolve(
				dispatch(
					fetchWrapper(
						url,
						parameters,
						method,
						lockByModal,
						fromState,
						disableErrors,
						undefined,
						token,
					),
				),
			);
		}, timeout);
	};
};

const handleMaintenanceMode = (
	data,
	status,
	{
		url,
		parameters,
		method,
		lockByModal,
		fromState,
		disableErrors,
		isStatic,
		token,
	},
	resolve,
) => {
	return (dispatch, getState) => {
		const state = getState();
		if (!state.app.maintenanceMode) {
			dispatch(toggleMaintenanceMode(true));
		}
		if (isStatic) {
			return resolve(false);
		}
		if (FetchService.noRepeatOnMaintenaceUrl(url)) {
			dispatch(getStatsData());
			if (!disableErrors) {
				dispatch(
					handleErrorsAction(
						{
							status,
						},
						url,
						parameters,
						method,
					),
				);
			}
			return resolve(false);
		}
		return setTimeout(() => {
			resolve(
				dispatch(
					fetchWrapper(
						url,
						parameters,
						method,
						lockByModal,
						fromState,
						disableErrors,
						undefined,
						token,
					),
				),
			);
		}, config.maintenanceModeTimeout);
	};
};
const handleUnavailableService = (data, status, { url }, resolve) => {
	return (dispatch) => {
		if (FetchService.noUnavailableService(url)) {
			return resolve({
				data,
				status,
			});
		}
		const dataString = getStringApiInfo(data?.error);
		const showUrl = dataString !== config.errors.moduleMaintenanceMode;
		dispatch(
			openModal(
				MODAL_ID.MAINTENANCE_MODULE,
				'fetch_503',
				showUrl ? { url, status } : null,
			),
		);
		return resolve(false);
	};
};
const handleFailedRequest = (
	data,
	responseParams,
	{
		url,
		parameters,
		method,
		lockByModal,
		fromState,
		isStatic,
		token,
		disableErrors,
	},
	resolve,
) => {
	return (dispatch) => {
		// console.log('handleFailedRequest', {data}, {status}, {url, parameters, method, lockByModal, fromState, disableErrors, isStatic, fetchErrorCounter});
		if (!fetchErrorCounter[url]) {
			fetchErrorCounter[url] = 0;
		}
		if (
			fetchCheckLimit > fetchErrorCounter[url] &&
			(FetchService.canRetryFailedUrl(url) || responseParams.status === 503) &&
			!isStatic
		) {
			fetchErrorCounter[url] += 1;
			return dispatch(
				handleRepeatRequest(
					data,
					responseParams,
					{
						url,
						parameters,
						method,
						lockByModal,
						fromState,
						disableErrors,
						isStatic,
						token,
					},
					resolve,
					config.serverErrorConnectionTimeout,
				),
			);
		}
		if (fetchErrorCounter[url]) {
			delete fetchErrorCounter[url];
		}
		if (responseParams.status === 503) {
			return dispatch(
				handleUnavailableService(
					data,
					responseParams.status,
					{
						url,
						parameters,
						method,
						lockByModal,
						disableErrors,
						fromState,
						isStatic,
						token,
					},
					resolve,
				),
			);
		}
		// console.log('handleFailedRequest - resolve', {data}, {status});
		return resolve({
			...data,
			...(data.status
				? { responseStatus: responseParams.status }
				: { status: responseParams.status }),
		});
	};
};
export const fetchWrapper = (
	url,
	parameters = {},
	method = 'GET',
	lockByModal,
	fromState,
	disableErrors,
	loaderId,
	token,
) => {
	return (dispatch, getState) => {
		return new Promise((resolve) => {
			const state = getState();
			// if (captchaUrlV2(url, loaderId) && !state.captcha.token && !bypassCaptcha && !state.captcha.lastCheckTimestamp && !state.captcha.bypass) {
			//   dispatch(showAlert('error', 'Please, validate captcha'));
			//   return resolve(false);
			// }
			// console.log('fetchWrapper', {url,
			//   parameters,
			//   method,
			//   lockByModal,
			//   fromState,
			//   disableErrors,
			//   loaderId} );
			if (!parameters.method) {
				parameters = {
					...parameters,
					method,
				};
			}
			if (parameters['Authorization'] || tokenService.token.value) {
				if (url.indexOf('auto/play') === -1) {
					// console.log('fetchWrapper', url, `${tokenService.value}`);
					if (!checkToken(tokenService.token).value) {
						if (state.user.isLogged) {
							dispatch(userSessionEnded());
						}
						if (lockByModal && !state.user.isLogged) {
							dispatch(openModal(MODAL_ID.NEW_ACCOUNT, url));
						}
						// console.log('fetchWrapper', {tokenService});
						return resolve({});
					}
				}
				const {
					// eslint-disable-next-line no-unused-vars
					Authorization,
					...data
				} = parameters;
				parameters = {
					...(data || {}),
					headers: {
						...(parameters.headers || {}),
						Authorization: createAuthToken(
							tokenService.token.value || checkToken(),
						),
					},
				};
			}

			let status = undefined;
			const getParametersResult = FetchService.getParameters(
				parameters,
				url,
				loaderId,
			);
			const callback = isFunction(getParametersResult.then)
				? () => {
						return getParametersResult.then((result) => {
							return FetchService.fetch(url, result, fromState, token).catch(
								(error) => {
									console.error('fetch - error', error, {
										result,
									});
									throw error;
								},
							);
						});
				  }
				: () => {
						return FetchService.fetch(
							url,
							getParametersResult,
							fromState,
							token,
						).catch((error) => {
							// console.error('fetch - error', error, {
							// 	getParametersResult
							// });
							throw error;
						});
				  };
			const handleResult = (response, responseParams) => {
				return resolve(
					dispatch(
						handleWrapperResponse(response, responseParams, {
							url,
							parameters: getParametersResult,
							method,
							lockByModal,
							fromState,
							disableErrors,
							token,
						}),
					),
				);
			};
			return callback()
				.then((response) => {
					status = response.status;
					const headers = {};
					const allHeaders = isFunction(response?.getAllResponseHeaders)
						? response.getAllResponseHeaders()
						: '';
					allHeaders
						.trim()
						.split(/[\r\n]+/)
						.forEach((string) => {
							const [key, value] = string.split(': ');
							headers[key] = value;
						});
					if (status === 403 && NEXT_CONFIG.DEV) {
						console.error(new Error(`Invalid Authorization - ${url}`));
					}
					return FetchService.getResponseData(response)
						.then((data) => {
							// console.log('getResponse', data, response);
							return handleResult(data, { status, headers });
						})
						.catch((error) => {
							// console.error('getResponseData - error', error);
							return handleResult(error, { status, headers });
						});
				})
				.catch((error) => {
					// console.error('fetchWrapper - error', error);
					return handleResult(error, { status });
				});
		});
	};
};
