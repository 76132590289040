import { cookieService } from '@common/controllers/cookieService';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { stringToJson } from '@common/methods/stringToJson';
import { getTokenFromPreloadedState } from '../../../methods/auth/getServerToken';
import { getTimestamp } from './timeService';

const getStorageToken = (ctx) => {
	// console.log('checkToken', {
	// 	lsToken: lsGet(LocalStorageKeys.TOKEN),
	// 	cookieToken: cookieService.get(LocalStorageKeys.TOKEN),
	// 	preloadedStateToken: getTokenFromPreloadedState(cookieService.get('preloaded_state', null, ctx), 'checkToken'),
	// });
	return (
		lsGet(LocalStorageKeys.TOKEN) ||
		cookieService.get(LocalStorageKeys.TOKEN, null, ctx) ||
		getTokenFromPreloadedState(
			cookieService.get('preloaded_state', null, ctx),
			'checkToken',
		)
	);
};

const parseServerToken = (token) => {
	token = stringToJson(token, {});
	// console.log('parseServerToken', token);
	if (!!token && (token.access_token || token.expires_in)) {
		return {
			value: token.access_token,
			expire:
				typeof token.expires_in === 'string'
					? getTimestamp(token.expires_in)
					: token.expires_in * 1000,
		};
	}
	return token;
};

const isValid = (token) =>
	token &&
	token.value &&
	token.expire &&
	getTimestamp(token.expire) > getTimestamp();

export const checkToken = (token, ctx) => {
	let checkedToken = token;
	if (checkedToken === undefined) {
		checkedToken = getStorageToken(ctx);
	}
	checkedToken = parseServerToken(checkedToken);
	// console.log('checkToken', {
	// 	token,
	// 	checkedToken,
	// 	curr: getTimestamp(),
	// 	tok: checkedToken ? getTimestamp(checkedToken.expire) : false,
	// 	isValid: isValid(checkedToken),
	// });
	if (isValid(checkedToken)) {
		cookieService.set(LocalStorageKeys.TOKEN, checkedToken);
		lsSet(LocalStorageKeys.TOKEN, checkedToken);
		return checkedToken;
	}
	lsClear(LocalStorageKeys.TOKEN);
	cookieService.clear(LocalStorageKeys.TOKEN);
	return {
		value: false,
		expire: false,
	};
};

class TokenClass {
	token = checkToken();

	set(token) {
		this.token = checkToken(token);
	}
}

export const tokenService = new TokenClass();
