import {
	ApiCurrenciesSettings,
	CurrenciesSettings,
	CurrencySettings,
} from '@legacyApp/types/wallet/CurrenciesSettings';
import {
	Currencies,
	CurrenciesLayout,
	CurrencyNetworkType,
	CurrencyTokenType,
	CurrencyType,
	Token,
} from '@legacyApp/types/wallet/CurrencyType';
import { loop } from '@legacyApp/client/modules/app/appService';
import { parseApiUrl } from '@legacyApp/methods/fetch/parseApiUrl';
import { get } from '@legacyApp/client/modules/app/axios';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { hour } from '@legacyApp/client/modules/app/time';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { generateUrl, ROUTE } from '../../routes';
import sortService, {
	DIRECTION,
} from '../../../LegacyApp/client/modules/app/sortService';

export const CURRENCIES_SETTINGS_UPDATE_INTERVAL = hour;
export const DEFAULT_CURRENCY = Currencies.btc as const;

export class CurrenciesSettingsClass {
	list: CurrencyType[];
	tokens: CurrencyTokenType[];
	allList: readonly (
		| CurrencyTokenType
		| typeof CurrenciesLayout.usd
		| typeof CurrenciesLayout.eur
	)[];
	settings: CurrenciesSettings;

	constructor() {
		this.list = lsGet(
			LocalStorageKeys.CURRENCIES_SETTINGS_LIST,
			Object.keys(Currencies).map((key) => Currencies[key]),
		);
		this.tokens = Object.keys(Token) as CurrencyTokenType[];
		this.allList = [
			...(Object.keys(Token) as CurrencyTokenType[]),
			CurrenciesLayout.usd,
			CurrenciesLayout.eur,
		];
		this.settings = lsGet(LocalStorageKeys.CURRENCIES_SETTINGS, {});
	}

	async fetchSettings(): Promise<{
		settings: CurrenciesSettings;
		tokens: CurrencyTokenType[];
		list: CurrencyType[];
	}> {
		const settings: CurrenciesSettings = JSON.parse(
			JSON.stringify(this.settings),
		);

		const response: {
			data: {
				settings: Record<CurrencyType, ApiCurrenciesSettings> | {};
				tokens: Array<CurrencyTokenType>;
				error?: any;
			};
		} = await get(parseApiUrl(generateUrl(ROUTE.apiSiteCurrencies))).catch(
			(error) => {
				// sentryError(new Error('Error - axios.get currencies settings'), {
				// 	error,
				// 	_: this,
				// });
				// console.log(error);
				return { data: { settings: {}, error, tokens: [] } };
			},
		);

		// if (!NEXT_CONFIG.PRODUCTION) {
		// 	response.data = {
		// 		btc: {
		// 			tip: {
		// 				amount_minimum: 1,
		// 			},
		// 			rain: {
		// 				amount_minimum: 1,
		// 			},
		// 			casino: {
		// 				amount_flash_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			sports: {
		// 				amount_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			chat: {
		// 				wager_minimum: 1,
		// 				profit_minimum: 1,
		// 			},
		// 		},
		// 		eth: {
		// 			tip: {
		// 				amount_minimum: 1,
		// 			},
		// 			rain: {
		// 				amount_minimum: 1,
		// 			},
		// 			casino: {
		// 				amount_flash_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			sports: {
		// 				amount_minimum: 1,
		// 				amount_maximum: 1,
		// 				profit_maximum: 1,
		// 			},
		// 			chat: {
		// 				wager_minimum: 1,
		// 				profit_minimum: 1,
		// 			},
		// 		}
		// 		// minTip: 0.07,
		// 		// minRain: 0.7,
		// 		// minDeposit: null,
		// 		// minWithdraw: 5,
		// 		// maxWithdrawPrecision: 6,
		// 		// minFlashAmount: 0.0014,
		// 		// betLimits: {
		// 		// 	max: 69000,
		// 		// 	maxProfit: 6900,
		// 		// },
		// 		// sportsLimits: {
		// 		// 	max: 69000,
		// 		// 	maxProfit: 6900,
		// 		// 	min: 0.007,
		// 		// },
		// 		// chat: {
		// 		// 	minWager: 0.35,
		// 		// 	minProfit: 0.18,
		// 		// },
		// 	};
		// }

		await loop(Object.keys(response.data?.settings || {}), (currency) => {
			settings[currency] = {
				...settings[currency],
				...response.data?.settings?.[currency],
				// rain: {
				// 	...response.data?.settings[currency].rain,
				// 	amount_minimum:
				// 		response.data?.settings[currency].rain.amount_minimum +
				// 		getTimestamp() / 1000000000,
				// },
			};
		});

		const listFromSettings = Object.keys(settings);

		const list = this.list.filter((currency) =>
			listFromSettings.includes(currency),
		);

		const responseTokens = response.data.tokens.map(mapApiTokenToLayoutToken);

		const tokens = Object.keys(Token).filter((token: CurrencyTokenType) =>
			responseTokens?.length ? responseTokens.includes(token) : true,
		) as CurrencyTokenType[];

		return { settings, tokens, list };
	}

	setList(list: CurrencyType[]) {
		lsSet(LocalStorageKeys.CURRENCIES_SETTINGS_LIST, list);
		this.list = list;
	}

	setTokens(tokens: CurrencyTokenType[]) {
		lsSet(LocalStorageKeys.CURRENCIES_SETTINGS_TOKENS, tokens);
		this.tokens = tokens;
	}

	checkSettings(settings: CurrenciesSettings) {
		return (
			settings &&
			Object.keys(settings).findIndex(
				(currencyKey) => !Number(settings[currencyKey]?.casino?.profit_maximum),
			) === -1
		);
	}

	setSettings(settings: CurrenciesSettings) {
		lsSet(LocalStorageKeys.CURRENCIES_SETTINGS, settings);
		this.settings = settings;
	}

	async update() {
		this.set(await this.fetchSettings());
	}

	set(props?: Awaited<ReturnType<typeof this.fetchSettings>>) {
		const { settings, list, tokens } = props;
		if (currenciesSettings.checkSettings(props?.settings)) {
			this.setSettings(settings);
		}
		if (list?.length) {
			this.setList(list);
		}
		if (tokens?.length) {
			this.setTokens(tokens);
		}
	}

	async start(props: Awaited<ReturnType<typeof this.fetchSettings>>) {
		this.set(props);
		await this.initInterval();
	}

	async initInterval() {
		this.update();
		setTimeout(async () => {
			this.initInterval();
		}, CURRENCIES_SETTINGS_UPDATE_INTERVAL);
	}

	getAllSettings() {
		// console.log('getAllSettings', this.settings);
		return this.settings;
	}

	getTokens() {
		return this.tokens;
	}

	getList() {
		return this.list;
	}

	getSettings(currency?: CurrencyType): CurrencySettings {
		const key = currency ? (currency.toLowerCase() as CurrencyType) : null;
		// if (!this.settings[key]) {
		// 	sentryError(new Error('Missing currency settings'), {
		// 		currency, key, settings: this.settings
		// 	});
		// }
		return this.settings[key] || {};
	}

	sortCurrency(
		a: CurrencyType,
		b: CurrencyType,
		dir: DIRECTION = DIRECTION.ASC,
	) {
		const a_ = a ? (a.toLowerCase() as CurrencyType) : null;
		const b_ = b ? (b.toLowerCase() as CurrencyType) : null;
		const indexA = this.list.indexOf(a_);
		const newA = indexA === -1 ? a_ : indexA + 1;
		const indexB = this.list.indexOf(b_);
		const newB = indexB === -1 ? b_ : indexB + 1;
		// console.log('sortCurrency', {
		//   dir, a, b, newA, newB,
		// });
		return sortService.sort(dir, newA, newB, a_, b_);
	}
}

export const currenciesSettings = new CurrenciesSettingsClass();

export const mapApiTokenToLayoutToken = (
	currency: CurrencyTokenType | CurrencyType | CurrencyNetworkType,
): CurrencyTokenType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case Currencies.matic: {
			return Token.pol;
		}
		case Currencies.optim: {
			return Token.op;
		}
		default: {
			return currency as CurrencyTokenType;
		}
	}
};

export const mapApiCurrencyToLayoutCurrency = (
	currency: CurrencyTokenType | CurrencyType | CurrencyNetworkType,
): CurrencyType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case Currencies.usdt: {
			return CurrenciesLayout.usd;
		}
		case 'busd': {
			return CurrenciesLayout.usd;
		}
		case 'usdc': {
			return CurrenciesLayout.usd;
		}
		default: {
			return mapApiTokenToLayoutToken(currency) as CurrencyType;
		}
	}
};

export const mapLayoutTokenToApiToken = (
	currency: CurrencyTokenType | CurrencyType | CurrencyNetworkType,
): CurrencyType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case Token.pol: {
			return Currencies.matic;
		}
		case Token.op: {
			return Currencies.optim;
		}
		default: {
			return currency.toLowerCase() as CurrencyType;
		}
	}
};

export const mapLayoutCurrencyToApiCurrency = (
	currency: CurrencyType,
): CurrencyType => {
	if (!currency) {
		return null;
	}
	switch (currency.toLowerCase()) {
		case CurrenciesLayout.usd: {
			return Currencies.usdt;
		}
		default: {
			return mapLayoutTokenToApiToken(currency) as CurrencyType;
		}
	}
};
